import styles from '../styles/LegalCenter.module.css';

const LegalCenter = () => {
  return (
    <div className={styles.legalCenter}>
      <h1>Legal Center h1</h1>
    </div>
  );
}

export default LegalCenter;