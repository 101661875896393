import styles from '../styles/Privacy.module.css';

const Privacy = () => {
  return (
    <div className={styles.privacy}>
      <h1>Privacy Policy</h1>
      {/* Add your privacy policy here */}
    </div>
  )
}

export default Privacy;