import styles from '../styles/TnC.module.css';

const TnC = () => {
  return (
    <div className={styles.tnc}>
      <h1>Terms and Conditions</h1>
      <p></p></div>
  )
}

export default TnC;

